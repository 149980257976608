.dataTable {
    height: 100%;
    width: 90%;
    padding: 10px;
    margin: auto;
    margin-top: 1%;

    .datatableTitle {
        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link {
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .datagrid {
        min-height: auto;
        max-height: fit-content;
        max-width: 100%;
        font-size: 1.3vmin;
    }

    .cellWithImg {
        display: flex;
        align-items: center;

        .cellImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .cellWithStatus {
        padding: 2%;
        border-radius: 5px;

        &.valid {
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
        }

        &.invalid {
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
        }
    }

    .cellAction {
        display: flex;
        align-items: center;
        gap: 15px;

        .viewButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: darkblue;
            border: 1px dotted rgba(0, 0, 139, 0.6);
            cursor: pointer;
        }
        .deleteButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px dotted rgba(220, 20, 60, 0.6);
            cursor: pointer;
        }
    }
}
