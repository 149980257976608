.widget {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.6);
    border-radius: 10px;
    background-color: white;

    .left,
    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-weight: bold;
            font-size: 1.7vmin;
            color: rgb(160, 160, 160);
        }

        .counter {
            font-size: 3.2vmin;
            font-weight: 300;
        }

        .link {
            width: max-content;
            font-size: 1.5vmin;
            border-bottom: 1px solid gray;
            color: black;
        }

        .percentage {
            display: flex;
            align-items: center;
            font-size: 1.7vmin;

            &.positive {
                color: green;
            }

            &.negative {
                color: red;
            }
        }

        .icon {
            font-size: 2.5vmin;
            padding: 0.75vmin;
            border-radius: 0.75vmin;
            align-self: flex-end;
        }
    }
}
