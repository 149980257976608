.home {
    display: flex;
    justify-content: center;
    width: 100%;

    .homeContainer {
        flex: 6;
        background-color: #f5f5f5;

        .widgets,
        .charts {
            display: flex;
            gap: 1vmin;
            margin-left: 2%;
            margin-right: 2%;
        }

        .widgets {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15vh;
        }

        .charts {
            padding: 1.5%;
            gap: 2%;
            height: 50vh;
            margin-bottom: 1%;
            flex: 1;

            .featured {
                display: flex;
                flex-direction: column;
                padding: auto;
                justify-content: space-between;
                height: 48vh;
            }

            .title {
                font-size: 2vmin;
            }

            .chart {
                height: 50vh !important;
                max-height: 48vh !important;
                width: auto !important;
                display: flex;
                align-items: center;
                padding: 1% !important;
                justify-content: space-between;

                .top {
                    padding: 1%;
                    height: auto;
                    margin-bottom: 2.5%;

                    .toggle {
                        height: 3vmin;
                        width: 5vmin;
                    }
                }

                .recharts-responsive-container {
                    max-width: 100% !important;
                    height: 100% !important;
                }

                .recharts-surface {
                    max-height: 40vh !important;
                    position: relative;
                }

                .recharts-curve {
                    min-width: 100% !important;
                    min-height: 100% !important;
                }

                .recharts-area-area {
                    width: 100% !important;
                }

                .recharts-wrapper {
                    max-height: 100% !important;
                    height: fit-content !important;
                    min-width: 100% !important;
                }

                .recharts-wrapper {
                    min-width: 100% !important;
                    max-height: 100% !important;
                    justify-self: center !important;
                    max-height: max-content;
                    max-width: max-content;
                }
            }
        }

        .listContainer {
            padding: 3%;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.6);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.6);
            margin-left: calc(2% + 1.5%); // padding + padding
            margin-right: calc(2% + 1.5%); // gap minus padding (2% - 1.5%)
            flex: 1;
            background-color: white;

            .table {
                width: 95%;
                margin: auto;
                padding: auto;
                margin-bottom: 1%;
            }

            .listTitle {
                font-size: 2vmin;
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}
