.featured {
    flex: 2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.6);
    padding: 1%;
    background-color: white;
    height: 100%;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: gray;
        font-size: 2.5vmin;
        margin: 2%;

        .title {
            justify-self: center;
            font-weight: 500;
            font-size: 100%;
        }
    }

    .bottom {
        padding: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2%;
        height: 100%;

        .featuredChart {
            width: 50%;
            height: 50%;

            .CircularProgressbar {
                height: 100%;
            }
        }

        .title {
            font-weight: 500;
            font-size: 100%;
            color: gray;
        }

        .amount {
            font-size: 5vmin;
        }

        .desc {
            font-weight: 300;
            font-size: 1.2vmin;
            color: gray;
            text-align: center;
        }

        .summary {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2%;

            .item {
                flex: 1;

                .itemTitle {
                    font-size: 90%;
                    text-align: center;
                    color: gray;
                }

                .itemResult {
                    justify-content: center;
                    display: flex;
                    font-size: 90%;
                    margin-top: 10%;
                    margin-left: auto;
                    margin-right: auto;

                    &.positive {
                        color: green;
                    }

                    &.negative {
                        color: red;
                    }
                }
            }
        }
    }
}
