.new {
    width: 100%;
    display: flex;

    .newContainer {
        flex: 6;

        .bottom {
            gap: 20%;
        }

        .top,
        .bottom {
            padding: 10px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            margin: 20px;
            display: flex;

            h1 {
                color: lightgray;
                font-size: 20px;
            }

            .left {
                flex: 1;
                text-align: center;
                display: flex;
                justify-content: center;

                img {
                    // margin-top: 10%;
                    width: 20vmin;
                    height: 20vmin;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right {
                flex: 2;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    gap: 30px;
                    justify-content: space-around;

                    .formInput {
                        width: 40%;

                        label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icon {
                                cursor: pointer;
                            }
                        }

                        input {
                            width: 100%;
                            padding: 5px;
                            border: none;
                            border-bottom: 1px solid gray;
                            outline: none;
                        }
                    }

                    button {
                        width: 150px;
                        padding: 10px;
                        border: none;
                        background-color: teal;
                        color: white;
                        font-weight: bold;
                        cursor: pointer;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
