.sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;
    min-width: 15vw;
    background-color: white;

    .top {
        height: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            font-size: 1vmax;
            font-weight: bold;
            color: #045d10;
        }
    }

    hr {
        height: 0;
        border: 0.5px solid rgb(230, 227, 227);
    }

    .center {
        padding-left: 4%;
        height: 85%;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .title {
                font-size: 1.4vmin;
                font-weight: bold;
                color: #999;
                margin-top: 8%;
                margin-bottom: 2%;
            }

            li {
                display: flex;
                align-items: center;
                padding: 2.5%;
                cursor: pointer;
                width: 100%;

                &:hover {
                    background-color: #b6fcbf;
                }

                .icon {
                    font-size: 2.5vmin;
                    color: #079d1b;
                }

                span {
                    font-size: 1.75vmin;
                    font-weight: 600;
                    color: #888;
                    margin-left: 4%;
                }
            }
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        width: 100%;

        span {
            font-size: 0.8vmax;
            margin: auto;
        }
    }
}
