.listTable {
    width: 100%;

    .table {
        width: 100%;

        table {
            .tableCell {
                font-size: 20px;
            }
        }
    }

    .cellWrapper {
        display: flex;
        align-items: center;
        .image {
            height: 32px;
            width: 32px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }
    }

    .status {
        padding: 5px;
        border-radius: 5px;

        &.Approved {
            color: green;
            background-color: rgba(0, 128, 0, 0.15);
        }

        &.Pending {
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.1);
        }
    }
}
