.list {
    display: flex;

    .listContainer {
        flex: 6;
        display: flex;
        flex-direction: column;

        .container {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            height: fit-content;
            width: 90%;
            margin: auto;
            padding: 2%;
            margin-top: 5%;

            .dataTable {
                // height: 50vh;
                padding: 0;
                margin-bottom: 0 !important;
            }
        }
    }
}
